import React, { useState, useRef, useEffect, useContext } from "react";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from "aws-amplify";
import { CustomAmpilfyAuthenticatorComponent, CustomAmpilfyAuthenticatorFormFields, 
  ChatMessage, ChatInput, Tooltip, SideMenu, TopNavigetionModelSettings } from "./components";

import { handleChatSubmit, clearChat } from "./handlers";

import awsconfig from './aws-exports';
import './App.css';
import './normalize.css'
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);

export default function App() {
  //Open AI API
  // https://platform.openai.com/docs/api-reference/chat/create?lang=python
  //add state for input and chat log
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([{"role": "system", "content": "You are a helpful coding assistant.  you are always frendly.  You not only write code, but you explain the code in a clear and concise fashion."}]);
  const [currentModel, setCurrentModel] = useState("openai_gpt_3_5_turbo_wikipedia"); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const chatLogContainerRef = useRef(null);
  const [modelParamTemperature, setModelParamTemperature] = useState(0.1);
  const [modelParamTopP, setModelParamTopP] = useState(0.5);
  const [modelParamFrequencyPenalty, setModelParamFrequencyPenalty] = useState(0.0);
  const [modelParamPresencePenalty, setModelParamPresencePenalty] = useState(0.0);
  const [credentials, setCredentials] = useState(null);

  
  //this is used to dispalyt the user's email address on the website
  useEffect(() => {
    async function getCurrentCredentials() {
      try {
        const currentCredentials = await Auth.currentUserInfo();
        setCredentials(currentCredentials);
      } catch (error) {
        if (error.name === 'NotAuthorizedException') {
          // The user's access token is not valid, sign them out.
          console.log("The user's access token is not valid, sign them out.");
        } else {
          console.error('Error fetching user info:', error);
        }
      }
    }
    getCurrentCredentials();
    //e.log( Auth.currentAuthenticatedUser());
    //console.log(Auth.currentCredentials());
    //console.log(Auth.currentUserInfo());
  }, []);

  // Scroll to the bottom of the chat log when the chat log changes
  useEffect(() => {
    if (chatLogContainerRef.current) {
      chatLogContainerRef.current.scrollTop = chatLogContainerRef.current.scrollHeight;
    }
  }, [chatLog]);
  
  
  // These event handlers update the parameter numbers 
  // when thsse user updates the sliders
  const handleModelParamTemperatureChange = (event) => {
    setModelParamTemperature(event.target.value);
  };
  const handleModelParamTopPChange = (event) => {
    setModelParamTopP(event.target.value);
  };
  const handleModelParamFrequencyPenaltyChange = (event) => {
    setModelParamFrequencyPenalty(event.target.value);
  };
  const handleModelParamPresencePenaltyChange = (event) => {
    setModelParamPresencePenalty(event.target.value);
  };

  
  return (

    <Authenticator 
    formFields={CustomAmpilfyAuthenticatorFormFields}
    components={CustomAmpilfyAuthenticatorComponent}
    hideSignUp={true}>
      {({ signOut, user }) => (
      <div className="App">
        <TopNavigetionModelSettings
          currentModel={currentModel}
          setCurrentModel={setCurrentModel}
          modelParamTemperature={modelParamTemperature}
          setModelParamTemperature={setModelParamTemperature}
          modelParamTopP={modelParamTopP}
          setModelParamTopP={setModelParamTopP}
          modelParamFrequencyPenalty={modelParamFrequencyPenalty}
          setModelParamFrequencyPenalty={setModelParamFrequencyPenalty}
          modelParamPresencePenalty={modelParamPresencePenalty}
          setModelParamPresencePenalty={setModelParamPresencePenalty}
          handleModelParamTemperatureChange={handleModelParamTemperatureChange}
          handleModelParamTopPChange={handleModelParamTopPChange}
          handleModelParamFrequencyPenaltyChange={handleModelParamFrequencyPenaltyChange}
          handleModelParamPresencePenaltyChange={handleModelParamPresencePenaltyChange}

          />
        <div className="mainbody">
          <SideMenu 
              clearChat={() => clearChat(setChatLog)}
              credentials={credentials}
              signOut={signOut}
          />
          <section className='chatbox'>
            <div className='chat-log' ref={chatLogContainerRef}>
              {chatLog.map((message, index) => (
                <ChatMessage key={index} message = {message} />
              ))}
            </div>
            <ChatInput
              input={input}
              setInput={setInput}
              handleChatSubmit={(event) => handleChatSubmit(event, credentials, input, chatLog, isSubmitting, setIsSubmitting, setChatLog, setInput, currentModel, modelParamTemperature, modelParamTopP, modelParamFrequencyPenalty, modelParamPresencePenalty)}
              isSubmitting={isSubmitting}
            />
          </section>
        </div>    
      </div>
      )}
      </Authenticator>
  );
}
