

// Sumbit the chat message to the API Gateway.  Return model response
export async function handleChatSubmit(event, credentials, input, chatLog, isSubmitting, setIsSubmitting, setChatLog, setInput, currentModel, modelParamTemperature, modelParamTopP, modelParamFrequencyPenalty, modelParamPresencePenalty) {

  event.preventDefault();
  if (isSubmitting) return;
  if (input === "") return; 
  console.log(input);
  console.log('choose model'); 
  console.log('currentModel', currentModel); 
  if( currentModel === "langchain_conversation_buffer_window_memory")
  {
    console.log('langchain_conversation_buffer_window_memorygpt-3.5-turbo'); 
    await setIsSubmitting(true);
    try {

      await setChatLog([...chatLog, {"role": "user", "content": input}])
      await setInput("");
      const  model_str = "gpt-3.5-turbo-0301"; 
      console.log("chatLog", [...chatLog, {"role": "user", "content": input}]); 
      console.log("credentials", credentials);

      const response = await fetch("https://rkrsv8szk8.execute-api.us-east-1.amazonaws.com/Prod/langchain_conversation_buffer_window_memory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify
        (
          {
            credentials: credentials,
            chat_log:  [...chatLog, {"role": "user", "content": input}],
            model: model_str,
            temperature: modelParamTemperature,
            top_p: modelParamTopP,
            frequency_penalty: modelParamFrequencyPenalty,
            presence_penalty: modelParamPresencePenalty
          }
        )
      });
      const data = await response.json();
      console.log("data", data.message);
      await setChatLog([...chatLog, {"role": "user", "content": input},
                  {"role": "assistant", "content": JSON.parse(data.message)}])

      console.log(data);
      console.log([...chatLog, {"role": "user", "content": input},
        {"role": "assistant", "content": JSON.parse(data.message)}])
    } catch (error) {
      console.log("error", error); // handle errors if necessary")
      console.error(error); // handle errors if necessary
    } finally {
      await setIsSubmitting(false);
    }

  }
  else if (currentModel === "gpt-3.5-turbo"){
    console.log('gpt-3.5-turbo'); 
    await setIsSubmitting(true);
    try {

      await setChatLog([...chatLog, {"role": "user", "content": input}])
      await setInput("");
      const  model_str = "gpt-3.5-turbo-0301"; 
      console.log("chatLog", [...chatLog, {"role": "user", "content": input}]); 
      console.log("credentials", credentials);

      //const response = await fetch("http://127.0.0.1:5000/openaicodedavinci", {
      const response = await fetch("https://rkrsv8szk8.execute-api.us-east-1.amazonaws.com/Prod/openai_gpt_3_5_turbo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify
        (
          {
            credentials: credentials,
            chat_log:  [{"role": "user", "content": input}],
            model: model_str,
            temperature: modelParamTemperature,
            top_p: modelParamTopP,
            frequency_penalty: modelParamFrequencyPenalty,
            presence_penalty: modelParamPresencePenalty
          }
        )
      });
      const data = await response.json();
      console.log("data", data.message);
      await setChatLog([...chatLog, {"role": "user", "content": input},
                  {"role": "assistant", "content": JSON.parse(data.message)}])

      console.log(data);
      console.log([...chatLog, {"role": "user", "content": input},
        {"role": "assistant", "content": JSON.parse(data.message)}])
    } catch (error) {
      console.log("error", error); // handle errors if necessary")
      console.error(error); // handle errors if necessary
    } finally {
      await setIsSubmitting(false);
    }  
  } 
  else if (currentModel === "openai_gpt_3_5_turbo_wikipedia"){
    await setIsSubmitting(true);
    console.log('openai_gpt_3_5_turbo_wikipedia');
    try {
      //console.log(`${input}`);
      await setChatLog([...chatLog, {"role": "user", "content": input}])
      await setInput("");
      const response = await fetch("https://rkrsv8szk8.execute-api.us-east-1.amazonaws.com/Prod/langchain_wikipedia", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            credentials: credentials,
            message:  input,
            model: currentModel,
            temperature: modelParamTemperature,
            top_p: modelParamTopP,
            frequency_penalty: modelParamFrequencyPenalty,
            presence_penalty: modelParamPresencePenalty
          }
        )
      });
      console.log("response", response);
      const data = await response.json();
      console.log("data", data.message);
      await setChatLog([...chatLog, {"role": "user", "content": input},
                  {"role": "assistant", "content": JSON.parse(data.message)}])

      console.log(data);
      console.log([...chatLog, {"role": "user", "content": input},
        {"role": "assistant", "content": JSON.parse(data.message)}])
    } catch (error) {
      console.error(error); // handle errors if necessary
    } finally {
      await setIsSubmitting(false);
    }
  } 
  else if (currentModel === "text-davinci-003"){
    await setIsSubmitting(true);
    console.log('text-davinci-003');
    try {
      //console.log(`${input}`);
      await setChatLog([...chatLog, {"role": "user", "content": input}])
      await setInput("");
      //const response = await fetch("http://127.0.0.1:5000/openaicodedavinci", {
      //const response = await fetch("https://388ix4nihd.execute-api.us-east-1.amazonaws.com/chatbot/openai_davinci", {
      const response = await fetch("https://rkrsv8szk8.execute-api.us-east-1.amazonaws.com/Prod/openai_davinci", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            credentials: credentials,
            message:  input,
            model: currentModel,
            temperature: modelParamTemperature,
            top_p: modelParamTopP,
            frequency_penalty: modelParamFrequencyPenalty,
            presence_penalty: modelParamPresencePenalty
          }
        )
      });
      console.log("response", response);
      const data = await response.json();
      console.log("data", data.message);
      await setChatLog([...chatLog, {"role": "user", "content": input},
                  {"role": "assistant", "content": JSON.parse(data.message)}])

      console.log(data);
      console.log([...chatLog, {"role": "user", "content": input},
        {"role": "assistant", "content": JSON.parse(data.message)}])
    } catch (error) {
      console.error(error); // handle errors if necessary
    } finally {
      await setIsSubmitting(false);
    }
  }
}


//clear chat function
export function clearChat(setChatLog) {
  setChatLog([]);
}



