import React from "react";
import "./ChatInput.css";

export const ChatInput = ({ input, setInput, handleChatSubmit, isSubmitting }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleChatSubmit(event);
    }
  };

  return (
    <div className="chat-input-holder">
      <form onSubmit={handleChatSubmit} className="chat-input-form">
        <textarea
          className="chat-input-textarea"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter text here"
          rows="1"
        > </textarea>
        <button
          type="submit"
          className="submit-button"
          disabled={isSubmitting}
        >
          Submit
        </button>
      </form>
    </div>
  );
};
