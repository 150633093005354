import React from "react";

export const SideMenu = ({ clearChat, credentials, signOut }) => {
  return (
    <aside className="sidemenu">
      <div className="sidemenu-button" onClick={clearChat}>
        <span>+</span>
        New Chat
      </div>
      <div className="saved-chat-logs">
        <select multiple size="4">
          <option value="chat1">Chat 1</option>
          <option value="chat2">Chat 2</option>
          <option value="chat3">Chat 3</option>
        </select>
      </div>
      <div>
        {credentials && (
          <div>
            <p>Current User: {credentials.attributes.email}</p>
          </div>
        )}
        <button className="signout-button" onClick={signOut}>
          Sign Out
        </button>
      </div>
    </aside>
  );
};