// sample input messages:
// mymessages = [
//   {"role": "system", "content": "You are a helpful assistant."},
//   {"role": "user", "content": "Who won the world series in 2020?"},
//   {"role": "assistant", "content": "The Los Angeles Dodgers won the World Series in 2020."},
//   {"role": "user", "content": "Where was it played?"}
// ]
// and this is the code that i want you to modify
import React from "react";

export const ChatMessage = ({ message }) => {
    if (message.role === "system") {
        return null;
    }

    return (
        <div className={`chat-message ${message.role === "assistant" && "assistant"}`}>
            <div className='chat-message-center'>
                <div className={`avatar ${message.role === "assistant" && "assistant"}`}></div>
                <div className='message'> {message.content} </div>
            </div>
        </div>
    );
};

  
  