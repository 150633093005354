import React from "react";
import { Tooltip } from './Tooltip';

export const TopNavigetionModelSettings = ({
  currentModel,
  setCurrentModel,
  modelParamTemperature,
  setModelParamTemperature,
  modelParamTopP,
  setModelParamTopP,
  modelParamFrequencyPenalty,
  setModelParamFrequencyPenalty,
  modelParamPresencePenalty,
  setModelParamPresencePenalty,
  handleModelParamTemperatureChange,
  handleModelParamTopPChange,
  handleModelParamFrequencyPenaltyChange,
  handleModelParamPresencePenaltyChange,
}) => {
  return (
     <div className="topnav">
     <label>Models</label>
     <select className='modelselection' onChange={(e) => setCurrentModel(e.target.value)}>
       <option value="openai_gpt_3_5_turbo_wikipedia">LangChain Wikipedia</option>
       <option value="langchain_conversation_buffer_window_memory">LangChain Memory</option>
       <option value="gpt-3.5-turbo">GPT-3.5-Turbo</option>
       <option value="text-davinci-003">Davinci</option>
     </select>
     <Tooltip text="Controls randomness:  Lowering results in less random complentions.  As the temperature approaches zero, the model will become deterministic and repetitive.">
       <div> Temperature {Number(modelParamTemperature).toFixed(1)}</div>
     </Tooltip>
     <input type="range" min="0" max="1" step="0.1" value={ modelParamTemperature} onChange={handleModelParamTemperatureChange}></input>
     <Tooltip text="Controls diversity via nucleus sampling:  0.5 means half of all likelihood-weighted options are considered.">
     TopP {Number(modelParamTopP).toFixed(1)}
     </Tooltip>
     <input type="range" min="0" max="1" step="0.1" value={modelParamTopP} onChange={handleModelParamTopPChange}></input>
     <Tooltip text="Controls How much to penalize new tokens based on their existing frequency in the text so far.  Decreases the model's likelihood to repeat the same line verbatim.">
     FrequencyPenalty {Number(modelParamFrequencyPenalty).toFixed(1)}
     </Tooltip>
     <input type="range" min="-2" max="2" step="0.1" value={modelParamFrequencyPenalty} onChange={handleModelParamFrequencyPenaltyChange}></input>
     <Tooltip text="How much to penalize new tokens based on whether they appear in the text so far.  Increases the model's liklihood to talk about new topics.">
     PresencePenalty {Number(modelParamPresencePenalty).toFixed(1)}
     </Tooltip>
     <input type="range" min="-2" max="2" step="0.1" value={modelParamPresencePenalty} onChange={handleModelParamPresencePenaltyChange} ></input>
   </div>
  );
};

